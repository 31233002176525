import axios from 'axios'
import store from '../store'
import { useToast } from 'vue-toastification';
import socketInstance from "@/services/Socket";
const toast = useToast();

export const sensor = {
  namespaced: true,

  state: {
    url_part: 'sensors',
    url_sensor: 'sensors/info/',
    url_sensors_for_gateway_me: 'mySensors/gateway/',
    url_sensor_attribute: 'sensor/attribute',
    url_sensor_holding_registers: 'sensors/holdingRegisters/',
    url_sensor_update_HR: 'sensors/updateHoldingRegisters',
    url_sensor_get_HR: 'sensors/getHoldingRegisters/',
    url_update_register_to_read : 'sensors/updateRegToRead/',
    url_sensors_overview : 'sensors/overview/',
    url_sensor_ai: 'sensors/ai/',
    id: null,
    holding_registers: [],
  },
  mutations: {
    async setId(state, payload) {
      state.id = payload.id;
      await this.dispatch('sensor/findInfo', {id: payload.id});
      await this.dispatch('sensor/findInfoOverview', payload);
      await this.dispatch('sensor/getRegisters', {sensor_id: payload.id});
      await this.dispatch('sensor/findSensorHoldingRegisters', {sensor_id: payload.id});
    },
    setHoldingRegisters(state, payload) {
      state.holding_registers = payload;
    },
    setHoldingRegisterValue(state, payload) {
      let hr = state.holding_registers.find(hr => hr.address == payload.address);
      hr.sensor_data_value = payload.value;
      hr.origin = 'Not saved';
      hr.last_update_user_id = store.state.auth.user.Auth?.system_user_id;
    }
  },
  actions: {
    async findAllForGatewayMe ({rootState, state, commit}, payload) {
      let id = payload.id;
      console.log('find all sensors for gateway ' + id);
      let url = rootState.settings.api_base + state.url_sensors_for_gateway_me + id;

      try {
        let rs = await axios.get(url);
        console.log('found sensors list for gateway ' + id, rs.data);
        if (typeof payload !== 'undefined') {
          commit('MyView/addChildrenToChild', {
            listType:payload.listType,
            idx:payload.idx,
            idx2: payload.idx2,
            data: rs.data},
            {root: true}
          )
        }
      } catch (err) { 
        console.error('Failed to load my sensor info for gateway.  Error was ' + id, {err});
      }
    },

    async findInfoOverview({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_sensors_overview + payload.id;
      try {
        let rs = await axios.get(url);
        await commit('apgList/setList', { listType: "sensor_overview", data: rs.data.Attributes},{root: true});
        await commit('apgList/setList', { listType: "gateway_overview", data: rs.data.GatewayAttributes},{root: true});
      } catch (err) {
        console.error(`Failed to load sensor overview.  Error was ${err}`);
      }
    },

    async insertHoldingRegisters({rootState, state}, payload) {
      console.log('updating holding register for ' + payload.sensorId + ' for holding_register_id ' + payload.holding_registers_id + ' having value = ' + payload.value)
      let url = rootState.settings.api_base + state.url_sensor_holding_registers + payload.sensorId + '/' + payload.holding_registers_id + '/' + payload.value;

      try {
        let rs = await axios.get(url);
        if( typeof rs === 'undefined' ) {
          console.log('Failed to insert holding register value for sensor' + payload.sensorId + ' rs was undefined.')
          return
        } 
        console.log('updated holding register for ' + payload.sensorId, rs.data)
        toast.success('Holding register updated successfully!');
      } catch (err) {
        toast.error('Failed to update holding register.');
        console.error('Failed to load sensor info for: ' + state.id + ' ERROR: ',{err})
      }
    },

    async updateSensorNewHoldingRegisters({rootState, state, dispatch}) {
      let errSaving = false;
      for (let HR of state.holding_registers) {
        if (HR.origin === 'Not saved') {
          HR.sensor_id = state.id;
          console.log('Holding registers changed', HR)
          let url = rootState.settings.api_base + state.url_sensor_update_HR
          console.log('update holding register url is ', url)
          try {
            let rs = await axios.post(url, HR)
            console.log(rs)
          } catch (err) {
            console.error('Failed to update sensor holding regs for: ' + state.id + ' ERROR: ', {err})
            errSaving = true;
          }
        }
      }
      if (!errSaving) {
        const gateway_id = store.state.apgList.ids.gateway.gateway
        await dispatch('findSensorHoldingRegisters', {sensor_id: state.id})
        await dispatch("gateway/findQueuedMessages", {id: gateway_id}, {root: true});
      }
    },

    async findSensorHoldingRegisters({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_sensor_get_HR + payload.sensor_id;
      try {
        let rs = await axios.get(url);

        if (rs == null) {
          console.error('Failed to find sensor info for sensor ' + state.id + ' rs was undefined.');
          return;
        } 
        console.log('Found sensor info for sensor ' + state.id, rs.data);
        if (rs.data != null) {
          commit('setHoldingRegisters', rs.data.HoldingRegisters);
          commit('apgList/setList', {listType: 'sensor_holdingRegisters', data:rs.data.HoldingRegisters},{root: true});
        }
      } catch (err) { 
        console.error('Failed to find holding regs for sensor: ' + state.id + ' ERROR: ', {err});
      }
    },
    
    async findInfo({rootState, state, commit, dispatch}, payload) {
      this.commit('settings/setIsLoading', true);
      console.log('finding sensor info for sensor ' + state.id);
      if (state.id == null) state.id = rootState.sensor.id;

      let url = rootState.settings.api_base + state.url_sensor + state.id;
      console.log('url with date range added is: ', url);

      try {
        let rs = await axios.get(url);
        console.log('rs is ', rs)
        if(rs == null) {
          console.log('Failed to find sensor info for sensor ' + state.id + ' rs was undefined.')
          return
        } 
        console.log('found sensor info for sensor ' + state.id, rs.data)
        if (rs.data) {
          await commit('apgList/setList', {listType: 'sensor_info',data:rs.data.Info[0]},{root: true})
          await commit('apgList/setExportData',{listType: 'sensor_readings',data:rs.data.ExportData},{root: true})
          await commit('apgList/setList', {listType: 'sensor_attribute',data:rs.data.Attributes},{root: true})

          // loop through attributes and if one is a dropdown set that value into apgList.
          let attr = rs.data.Attributes
          console.log('Loading sensor attributes and setting dropdown values', attr)
          for(let i= 0; i < attr.length; i++) {
            if(attr[i].attribute_type == 'dropdown') {
              let table = attr[i]?.attribute_key?.toLowerCase().replace(' ', '_');
              await commit('apgList/setDropdownValue', {
                table,data:attr[i].attribute_value,
                idx:0,
                listType:'sensor_attribute'
              }, {root:true});
            }
          }

          let gateway_id = rs.data.Info[0]['gateway_id'];
          // update gateway data with new id
          await commit('gateway/setId', gateway_id, {root: true});

          // load alarms
          await dispatch('alarm/fetchAlarms', null, {root: true});

          // load the default register on sensor load or the existing register if provided
          let address = rs.data.Attributes.find(a => a.attribute_name === 'addressToRead')?.attribute_value;
          await commit('register/setRegisterToRead', {address, upsert: false}, {root: true});
          if (payload.registerAddress != null) {
            await dispatch('register/loadRegister', payload.registerAddress, {root: true});
          } else {
            await dispatch('register/loadRegister', address, {root: true});
          }

          // set the socket listener for this sensor and register
          socketInstance.sendData('watching_sensor', {sensor_id: state.id, register: address});
        }
      } catch (err) { 
        console.error('Failed to load my sensor info for: ' + state.id + ' ERROR: ',{err})
      }
    },

    async getRegisters({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_sensor + payload.sensor_id + '/registers';
      console.log('get registers url: ', url);

      let rs = await axios.get(url);
      console.log('get registers response', rs);

      if (rs?.status === 200) {
        await commit('apgList/setList', {listType: 'sensor_registers', data: rs.data}, {root: true});
      } else {
        toast.error('Failed to get sensor registers');
        console.error('getSensorRegisters error:', rs?.data?.error);
      }
    },

    async readAllHoldingRegisters({rootState, state}, payload) {
      let sensorId = payload.sensorId;
      let url = rootState.settings.api_base + state.url_sensor_holding_registers + 'readAll';
      console.log('reading all holding registers for sensor ' + sensorId);
      console.log('url is ', url);

      let rs = await axios.post(url, {
        sensorId: sensorId,
        powerLine: payload.powerLine,
      });
      console.log('read all holding registers response', rs);

      if (rs?.status === 200) {
        toast.success('Read All Holding Registers message queued successfully!');
        // refresh sensor info
        await store.dispatch('sensor/findInfo', {
          id: sensorId,
          listType: 'group_gateway_sensor',
          chart: true
        });
        await store.dispatch('sensor/findInfoOverview', {id: sensorId});
        await store.dispatch('sensor/getRegisters', {sensor_id: sensorId});
      } else {
        toast.error('Failed to queue read all holding registers message. Please try again.');
        console.error('readAllHoldingRegisters error:', rs?.data?.error);
      }
    }
  },

  getters: {
    id: state => state.id,
  }
}