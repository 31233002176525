<template>
  <div>
    <font-awesome-icon icon="file-export" @click="downloadCSVData" style="cursor: pointer" title="Export Data"/>
  </div>
</template>

<script>
import {useStore} from 'vuex'

export default {
  props: {
    dataSourceKey: String,
    outputFileName: String,
  },
  setup(props) {
    let store = useStore()

    function arrayToCSV(data) {
      if (!data || !data.length) return '';
      // Extract the keys (headers) from the first object in the array
      const headers = Object.keys(data[0]);
      // Create the CSV rows by mapping over each object and joining values with commas
      const rows = data.map(obj =>
          headers.map(header => JSON.stringify(obj[header] ?? '')).join(',')
      );
      // Combine headers and rows, separating them with new lines
      return [headers.join(','), ...rows].join('\n');
    }

    function downloadCSVData() {
      let data = store.getters["register/readings"];
      const csv = arrayToCSV(data);

      const dt = new Date();
      const curr_date = dt.getFullYear() + "-" + (((dt.getMonth() + 1) < 10) ? "0" : "") + (dt.getMonth() + 1) + "-" + ((dt.getDate() < 10) ? "0" : "") + dt.getDate();

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = curr_date + "__" + props.dataSourceKey.replace("_", "") + "__" + props.outputFileName;
      anchor.click();
    }

    return {
      downloadCSVData
    }
  }
}

</script>