import axios from "axios";
import {useToast} from "vue-toastification";

const toast = useToast();

export const AI  = {
  namespaced: true,

  state: {
    predictions: [],
  },

  actions: {
    async getPredictions({ rootState, state, dispatch }) {
      try {
        let chartType = rootState.chart.type.group_gateway_sensor;
        let sensorId = rootState.sensor.id;
        let registerAddress = rootState.register.address;

        let url = `${rootState.settings.api_base}predictions/${sensorId}/${registerAddress}`;
        console.log('getPredictions url', url);

        let rs = await axios.get(url,  { params: { chartType: chartType }});

        let chartAiEnabled = rootState.chart.showing_predictions;
        // handle response
        if (rs.status === 200) {
          if (rs.data.length === 0) return;
          state.predictions = rs.data;
          await dispatch('sendToChart');
        } else if (rs.status === 202) {
          if (chartAiEnabled) {
            toast.warning('AI prediction data is still being processed', {timeout: 4000});
          }
        } else {
          toast.error('Failed to get AI prediction data');
          console.error('APG AI error:', rs.data.error);
        }
      } catch (e) {
        console.error('Failed to get AI prediction data', e);
      }
    },

    async sendToChart({state}) {
      await this.commit('chart/setChartPredictionData', state.predictions);
    }
  },

  mutations: {
    clearPredictions(state) {
      state.predictions = [];
    }
  },

  getters: {
    hasPredictions(state) {
      return state.predictions.length > 0;
    },
    predictions: state => state.predictions,
  }
}
