<template>
  <div class="error-page">
    <h1>500 - Internal Server Error</h1>
    <p>Something went wrong on our end. Please try again later.</p>
  </div>
</template>

<script>
export default {
  name: 'Error500'
};
</script>

<style scoped>
.error-page {
  text-align: center;
  margin-top: 50px;
}

h1 {
  color: var(--color-txt);
}

p {
  color: var(--color-txt);
}
</style>
