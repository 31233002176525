import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/pages/Home.vue";
import Login from "./components/pages/Login.vue";
import Register from "./components/pages/Register.vue";
import Gateways from "./components/pages/Gateways.vue"
import ForgotPassword from "./components/pages/ForgotPassword.vue"
import VerifyRegister from './components/pages/VerifyRegister.vue'
import ChangePassword from './components/pages/ChangePassword.vue'
import AuditDashboard from './components/pages/AuditDashboard.vue'
import LockEmail from "@/components/pages/LockEmail.vue";
import store from './store/index.js'
import routeAccessService from "@/services/routeAccess.service";
import Error500 from './components/pages/Error500.vue';
import NotFound from "@/components/pages/NotFound.vue";

// lazy-loaded
const Profile = () => import("./components/pages/Profile.vue")
const BoardAdmin = () => import("./components/pages/BoardAdmin.vue")

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: '/user/verify/:uuid',
    component: VerifyRegister,
  },
  {
    path: '/user/lockEmail/:email',
    component: LockEmail,
  },
  {
    path: '/user/forgotpassword/:uuid',
    component: ChangePassword,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/auditDB",
    name: "Audit dashboard",
    // lazy-loaded
    component: AuditDashboard,
  },
  {
    path: "/gateways",
    name: "gateways",
    component: Gateways,
  },
  {
    path: '/500',
    name: 'Error500',
    component: Error500,
  },
  // Catch-all route for 404 errors
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound, // You would need to create a NotFound.vue component
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Combined beforeEach guard
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/ForgotPassword', '/user/verify', '/user/lockEmail', '/user/forgotpassword', '/500'];
  const loggedIn = localStorage.getItem('user');
  const user = loggedIn ? JSON.parse(localStorage.getItem('user')) : null;
  const authRequired = !publicPages.includes(to.path);
  const user_type = user?.user?.Info?.user_type;

  if (authRequired && !loggedIn) {
    localStorage.removeItem('user');
    next('/login');
  } else if (authRequired && user) {
    store.dispatch('auth/refreshCurrentUserAttributes', to.path)
      .then(() => {
        let allowedPages = routeAccessService(user_type);
        if (allowedPages.includes(to.path)) {
          next();
        } else {
          next('/login');
        }
      })
      .catch(e => {
        console.error('User auth info refresh error:', e);
        next('/login');
      });
  } else {
    next();
  }
});

export default router;
