<template>
  <div v-if="registers.length > 0 || registers.some(r => r.deleted === false)">
    <div class="field" v-for="reg in registers" :key="reg.address" style="min-width: 150px">
      <OtherModbusDefinition v-if="reg.deleted === false"
          :sensor-id="sensorId"
          :registerAddress="reg.address"
      />
    </div>
  </div>
  <div v-else>
    <p>No Registers Found. Please add one.</p>
  </div>
  <div>
    <i class="bi bi-plus-circle" @click="addEmtpyRegister" title="Add New Register"></i>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed, defineProps} from "vue";
import OtherModbusDefinition from "@/components/shared/lists/other-modbus-definition.vue";

const store = useStore();
// eslint-disable-next-line vue/no-setup-props-destructure
const {sensorId} = defineProps(['sensorId']);

const addEmtpyRegister = function() {
  store.commit('ConfigWizard/setSensorRegister', {
    sensorId,
    register: {
      address: null,
      quantity: null,
      application_sensor_type_id: null,
      deleted: false
    }
  });
}

const registers = computed(() => {
  let registers = store.state.ConfigWizard.sensors.find(s => s.sensor_id === sensorId)?.registers ?? [];
  return registers.filter(r => !r.deleted).sort((a, b) => a.address - b.address);
});
</script>

<style scoped>
.bi {
  cursor: pointer;
  font-size: 1.75rem;
  color: var(--color-txt);
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.bi:hover {
  color: green;
}
</style>