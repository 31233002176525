<template>
  <li class="list-group-item">
    <attributeBoolean
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'boolean'"
    />
    <attributeEmail
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'email'"
    />
    <attributeText
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'text'"
    />
    <attributeDate
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'date'"
    />
    <attributeNumber
      :name="keyName"
      :value="value"
      :min="min"
      :max="max"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'number'"
    />
    <attributeDateTime
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'dateTime'"
    />
    <attribute-dropdown
      :name="keyName"
      :value="value"
      :id="idx"
      :attInfoId="attInfoId"
      listType="sensor_attribute"
      :ownerId="ownerId"
      :attId="attId"
      :disabled="disabled"
      v-if="type == 'dropdown'"
      :table="keyName?.toLowerCase()?.replace(' ', '_')"
    />
  </li>
</template>

<script>
import AttributeBoolean from '../attribute_render/AttributeBoolean.vue'
import AttributeEmail from '../attribute_render/AttributeEmail.vue'
import AttributeText from '../attribute_render/AttributeText.vue'
import AttributeDate from '../attribute_render/AttributeDate.vue'
import AttributeDateTime from '../attribute_render/AttributeDateTime.vue'
import AttributeNumber from '../attribute_render/AttributeNumber.vue'
import AttributeDropdown from '../attribute_render/AttributeDropdown.vue'

export default {
  props: {
    value: [String, Number],
    keyName: String,
    idx: Number,
    type: String,
    attId: Number,
    attInfoId: Number,
    ownerId: Number,
    min: Number,
    max: Number,
  },
  components: {
    AttributeBoolean,
    AttributeEmail,
    AttributeText,
    AttributeDate,
    AttributeNumber,
    AttributeDateTime,
    AttributeDropdown,
  },
  setup() {
  },
}
</script>
