<template>
  <ul class="card-header" style="list-style-type: none; padding-left: 5%">
    Register
    <li v-for="reg in registers" :key="reg" class="clearfix" style="display: flex; align-items: center;">
      <!-- stars -->
      <i
          class="bi"
          :class="isRegToRead(reg.register) ? 'bi-star-fill' : 'bi-star'"
          style="cursor: pointer; margin-right: 10px; font-size: .75rem;"
          title="Set default register"
          @click="setRegToRead(reg.register)"
      ></i>
      <button
          @click="readRegister(reg.register)"
          :class="{ active: selectedReg(reg.register) }"
          style="flex-grow: 1;"
      >
        <span class="fa-pull-left">{{ reg.name ? 'Name:' : 'Register:' }}</span>
        <span class="fa-pull-right">{{ reg.name ? reg.name : reg.register }}</span>
        <br />
        <span class="fa-pull-left">Latest Reading:</span>
        <span class="fa-pull-right">{{ reg.value }} {{ reg.units }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'ReadRegisterList',
  setup() {
    const store = useStore();
    const readRegister = async function (value) {
      await store.dispatch('register/loadRegister', value);
    };

    const selectedReg = function (reg) {
      return store.state.register.address == reg;
    };

    const isRegToRead = function (reg) {
      return store.getters['register/registerToRead'] == reg;
    };

    const setRegToRead = function (reg) {
      store.commit('register/setRegisterToRead', { address: reg });
    };

    return {
      registers: computed(() => {
        let registers = store.state.apgList.List?.sensor_registers;
        if (!registers) return;

        // group by register
        let regGroups = registers.reduce((r, a) => {
          r[a.register] = [...r[a.register] || [], a];
          return r;
        }, {});

        // format for display
        let regList = [];
        for (let idx in regGroups) {
          let reg = regGroups[idx];
          let name = reg.find(x => x.attribute_name == 'register_name')?.attribute_value;
          let val = reg.find(x => Object.hasOwn(x,'sensor_data_value'))?.sensor_data_value;
          let unit = reg.find(x => x.attribute_name == 'register_units')?.attribute_value;
          let register = reg.find(x => Object.hasOwn(x,'register'))?.register;
          regList.push({
            name: name,
            value: val,
            units: unit,
            register: register,
          });
        }
        return regList;
      }),
      readRegister,
      selectedReg,
      isRegToRead,
      setRegToRead,
    }
  }
}
</script>

<style scoped>
li {
  font-size: 10pt;
}
button {
  width: 100%;
  background-color: var(--color-bg-sec);
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}
.active {
  box-shadow: none!important;
  background-color: var(--btn-pressed);
  transition: background 0.5s ease, color 0.5s ease;
}

.bi-star-fill {
  color: var(--color-sec);
}

i.bi:hover {
  color: gray;
}
</style>
