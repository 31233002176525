<template>
  <div class="not-found-page">
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <router-link to="/">Go back to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
.not-found-page {
  text-align: center;
  margin-top: 50px;
}

h1 {
  color: var(--color-txt);
}

p {
  color: var(--color-txt);
}

a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}
</style>
