<template>
  <ul v-if="gatewayOverviewList" class="card-header" style="list-style-type: none; padding-left: 5%">
    Gateway
    <li v-for="prop in gatewayOverviewList" :key="prop" class="clearfix">
      <span class="fa-pull-left" v-bind:style="{color: prop.color}">{{prop.attribute_pretty_name}}:</span>
      <span v-if="prop.icon == null" class="fa-pull-right" v-bind:style="{color: prop.color}">{{prop.time? timeFilter(prop) : prop.attribute_value}} {{prop.unit_str}}</span>
      <span v-if="prop.icon != null" class="fa-pull-right" v-bind:class="prop.icon" v-bind:style="{ color: prop.color }"> </span>
    </li>
  </ul>
  <ul v-if="sensorOverviewList" class="card-header" style="list-style-type: none; padding-left: 5%">
    Sensor
    <li v-for="prop in sensorOverviewList" :key="prop" class="clearfix">
      <span class="fa-pull-left" v-bind:style="{color: prop.color}">{{prop.attribute_pretty_name}}:</span>
      <span class="fa-pull-right" v-bind:style="{color: prop.color}">{{prop.attribute_value}} {{prop.unit_str}}</span>
    </li>
  </ul>
  <ReadRegisterList v-if="registers?.length > 0"/>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import moment from 'moment';
import ReadRegisterList from "@/components/shared/lists/read-register-list.vue";

export default {
name: "OverviewList",
  components: {ReadRegisterList},
  methods: {
    timeFilter: ((attr) => moment(attr.attribute_value).local().format('MMM Do YY, h:mm:ss a')),
  },
  setup() {
    const store = useStore();
    return {
      gatewayOverviewList: computed( () =>
      {
        let gtwyAttrs = store.state.apgList.List.gateway_overview;
        if (!gtwyAttrs) return gtwyAttrs;
        // remove gatewayType from list (need it for attribute tab, not overview)
        return gtwyAttrs.filter(i => i.attribute_name !== 'gatewayType');
      }),
      sensorOverviewList: computed(() => store.state.apgList.List.sensor_overview),
      registers: computed(() => store.state.apgList.List.sensor_registers),
      lastHeardList: computed(() => {
        let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
        let chartData = store.state.chart?.data?.group_gateway_sensor;
        let lastHeard = chartData?.[chartData.length - 1]?.x;
        let lastVal = chartData?.[chartData.length - 1]?.y;
        let name = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_name')?.attribute_value;
        return [{
            attribute_pretty_name: "Reading",
            attribute_value: lastVal,
            unit_str: units == 0 ? '' : units,
          }, {
            attribute_pretty_name: "Time",
            attribute_value: lastHeard,
            time: true
          }, {
            attribute_pretty_name: "Name",
            attribute_value: name === 0 ? '' : name
          }]
      }),
    }
  }
}
</script>

<style scoped>
li {
  font-size: 10pt;
}

.bi.icon-sm {
  font-size: 1rem; /* small size */
}
.bi.icon-md {
  font-size: 1.25rem; /* medium size */
}
.bi.icon-lg {
  font-size: 2rem; /* large size */
}
.bi.icon-xl {
  font-size: 2.5rem; /* extra large size */
}

</style>