import axios from 'axios'
import { useToast } from 'vue-toastification';

const toast = useToast();

export const alarm = {
  namespaced: true,
  state: {
    alarm_set: [],
    original_alarm_set: [],
    alarm_history: [],
    alarm_type_options: [],
    alarm_action_type_options: [],
    
    url_get_user_sensor : 'alarm/getSensorUsersContact/',
    url_acknowledge_alarm : 'alarm/acknowledge/',
    url_save_alarms: 'alarm/saveAll'
  },

  mutations: {
    setAlarmSet(state, data) {
      state.alarm_set = data;
      data.forEach(alarm => {
        alarm.alarm_set_point = parseFloat(alarm.alarm_set_point);
      });
      state.original_alarm_set = JSON.parse(JSON.stringify(data));
    },
    setAlarmHistory(state, data) {
      state.alarm_history = data;
    },
    setAlarmCountActive(state, data) {
      state.alarm_count_active = data;
    },
    setAlarmType(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.alarm_type_id = data.alarm_type_id;
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    setAlarmTypeOptions(state, data) {
      state.alarm_type_options = data;
    },
    setAlarmActionType(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.alarm_action_type_id = data.alarm_action_type_id;
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    setAlarmActionTypeOptions(state, data) {
      state.alarm_action_type_options = data;
    },
    addNewAlarm(state, data) {
      state.alarm_set.push(data);
    },
    updateAlarm(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      Object.assign(alarm, data);
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    updateAlarmStatus(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      if (data.status === 'deleted') {
        alarm.status = 'deleted';
        return;
      }
      if (alarm.status === 'new') {
        return;  // no need to update status if alarm is new and not deleted
      }
      // Check if alarm has changed
      let original_alarm = state.original_alarm_set.find(i => i.alarm_id === data.id);
      // eslint-disable-next-line no-unused-vars
      let { status: alarmStatus, ...alarmNoStatus } = alarm;
      // eslint-disable-next-line no-unused-vars
      let { status: originalAlarmStatus, ...originalAlarmNoStatus } = original_alarm;
      if (JSON.stringify(alarmNoStatus) === JSON.stringify(originalAlarmNoStatus)) {
        alarm.status = 'existing';
        return;  // no need to update status if alarm hasn't changed
      }
      alarm.status = data.status;
    },
    setSelectedNotifications(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.selected_notify = data.selected_notify;
      if (alarm.status === 'new') {
        return;  // only update notifications for existing alarms
      }
      this.dispatch('alarm/SaveSelectedNotifications', {alarm_id: data.id, selected_notify: data.selected_notify});
    },
    setHysteresis(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.alarm_set_point_2 = parseFloat(data.hysteresis);
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    setSetPoint(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.alarm_set_point = parseFloat(data.alarm_set_point);
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    setRegister(state, data) {
      let alarm = state.alarm_set.find(i => i.alarm_id === data.id);
      alarm.alarm_register = data.alarm_register.toString();
      this.commit('alarm/updateAlarmStatus', {id: data.id, status: 'updated'});
    },
    resetAlarmSet(state) {
      state.alarm_set = JSON.parse(JSON.stringify(state.original_alarm_set));
    }
  },

  actions: {
    async fetchAlarms ({rootState, commit}) {
      let gatewayId = rootState.gateway.id;
      let url = `${rootState.settings.api_base}alarm/${gatewayId}`;

      try {
        let rs = await axios.get(url);

        commit('setAlarmSet', rs.data.AlarmSet);
        commit('setAlarmHistory', rs.data.AlarmHistory);
        commit('setAlarmCountActive', rs.data.AlarmCountActive);
        commit('setAlarmTypeOptions', rs.data.AlarmTypeOptions);
        commit('setAlarmActionTypeOptions', rs.data.AlarmActionTypeOptions);

      } catch (err) {
        console.error('Failed to retrieve alarm(s).  Error was ');
        console.error({err});
      }
    },

    async AcknowledgeAlarm ({rootState, commit,state}, payload) {
      let url = rootState.settings.api_base + state.url_acknowledge_alarm + payload.alarm_data_id;
      try {
        let rs = await axios.get(url)
        console.log(rs)
        commit('apgList/setList', { listType:"alarm_history", data: rs.data.AlarmHistory }, {root: true});
        commit('apgList/setList', { listType:"alarm_count_active", data: rs.data.AlarmCountActive }, {root: true});
      } catch (err) {
        console.error('Failed to get reading control function ',err)
      }
    },
    
    async AddNewAlarm ({rootState, state, commit, dispatch}) {
      try {
        let sensor_id = rootState.sensor.id;
        let currentAlarmIds = state.alarm_set.map(alarm => alarm.alarm_id);
        let tempAlarmId;
        do {
          tempAlarmId = Math.floor(Math.random() * 100000);
        } while (currentAlarmIds.includes(tempAlarmId));

        let json = {
          'alarm_id': -tempAlarmId,
          "sensor_address": '0',
          'alarm_type_id': '0',
          'alarm_set_point_2': undefined,
          "alarm_register":"0",
          "register_type_id": "0",
          "alarm_set_point":"0",
          "toWhom": sensor_id == null? [] : await dispatch('fetchNotificationOptions', sensor_id),
          "status":"new",
        }
        commit('addNewAlarm', json);
      } catch (err) {
        console.error('Failed to add new alarm, could not find sensor info ',err)
      }
    },

    async SaveAlarms({state, rootState, commit, dispatch}) {
      let url = rootState.settings.api_base + state.url_save_alarms;
      let gatewayId = rootState.gateway.id;
      try {
        await axios.post(url,{
          alarms: state.alarm_set,
          gateway_id: gatewayId
        });
        // refresh alarms, pending messages, and info overview
        await dispatch('fetchAlarms');
        await dispatch('gateway/findQueuedMessages', {id: gatewayId}, {root:true});
        await dispatch('gateway/findInfoOverview', {id: gatewayId}, {root:true});
        await commit('MyView/addGatewayPendingMessages', { gatewayId: gatewayId }, {root: true});
        toast.success('Queued the alarms message!');
      } catch(err) {
        console.error('SaveAlarms ERROR: error was', err);
        toast.error('Unable to queue alarms message. Please try again later.');
      }
    },

    async fetchNotificationOptions({rootState, state}, sensorId) {
      let url = rootState.settings.api_base + state.url_get_user_sensor + sensorId;
      console.log('url is', url);
      try {
        let rs = await axios.get(url);
        return rs.data;
      } catch (e) {
        console.error('Failed to fetch notification options', e);
        toast.error('Failed to fetch notification contacts');
      }
    },

    async SaveSelectedNotifications({rootState}, payload) {
      let url = `${rootState.settings.api_base}alarm/notifications/${payload.alarm_id}`;
      try {
        await axios.post(url, {selected_notify: payload.selected_notify});
        toast.success('Alarm notifications saved!');
      } catch (err) {
        console.error('Failed to save notifications', err);
        toast.error('Failed to save alarm notifications');
      }
    }
  },

  getters: {
    alarm_set: state => state.alarm_set,
    alarm_history: state => state.alarm_history,
    alarm_count_active: state => state.alarm_set.filter(alarm => alarm.active != null).length,
    setAlarmStatus: state => id => state.alarm_set.find(i => i.alarm_id === id).status,
    selectedNotify: state => id => state.alarm_set.find(i => i.alarm_id === id).selected_notify,
    alarm: state => id => state.alarm_set.find(i => i.alarm_id === id),
    toWhom: state => id => state.alarm_set.find(i => i.alarm_id === id).toWhom,
  }
}