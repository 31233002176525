<template>
  <div class="item" v-if="register">
    <div class="scrollable">
      <div class="content">
        <div class="field">
          <small class="d-block text-muted">Starting Address</small>
          <input class="form-control-sm"
                 type="number"
                 :value="getAddress()"
                  @change="setAddress($event)"
          />
        </div>

        <div class="field">
          <small class="d-block text-muted">Register Quantity</small>
          <input class="form-control-sm"
                 type="number"
                 :value="getQuantity()"
                  @change="setQuantity($event)"
          />
        </div>

        <div class="field">
          <small class="d-block text-muted">Function Code</small>
          <select class="form-control-sm text-center select"
                  :value="getFunction()"
                  @change="setFunction($event)">
            <option v-for="func in functionTypeOptions"
                    :key="func.application_sensor_type_id"
                    :value="func.application_sensor_type_id">
              {{ func.application_type }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <i class="bi bi-trash" @click="deleteRegister" title="Delete Register"></i>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps} from "vue";
import {useStore} from "vuex";

// eslint-disable-next-line vue/no-setup-props-destructure
const {sensorId, registerAddress} = defineProps(['sensorId', 'registerAddress']);
const store = useStore();

const sensor = computed({
  get() {
    return store.state.ConfigWizard.sensors.find(s => s.sensor_id === sensorId);
  },
  set(value) {
    store.commit('ConfigWizard/SetSensor', value);
  }
});

const register = computed(() => sensor.value?.registers.find(r => r.address == registerAddress));
const gcr = computed(() => store.state.readingControl.controls.find(gcr => gcr.sensor_id == sensorId && gcr.sensor_register == registerAddress));
const sensorReg = computed(() => sensor.value?.registers?.find(r => r.address === registerAddress));

const getAddress = function() {
  let address = gcr.value?.sensor_register || sensorReg.value?.address || null;
  register.value.address = address;
  return address;
};
const setAddress = function(e) {
  register.value.address = parseInt(e.target.value);
};

const getQuantity = function() {
  let quantity = gcr.value?.length || sensorReg.value?.quantity || null;
  register.value.quantity = quantity;
  return quantity;
};
const setQuantity = function(e) {
  register.value.quantity = parseInt(e.target.value);
};

const getFunction = function() {
  let func = gcr.value?.application_sensor_type_id || sensorReg.value?.application_sensor_type_id || null;
  register.value.application_sensor_type_id = func;
  return func;
};
const setFunction = function(e) {
  register.value.application_sensor_type_id = parseInt(e.target.value);
};

const functionTypeOptions = [
    {
      application_sensor_type_id: 4,
      application_type: '03'
    }, {
      application_sensor_type_id: 5,
      application_type: '04'
    },
];

const deleteRegister = function() {
  register.value.deleted = true;
};
</script>

<style scoped>
li {
  cursor: pointer;
}
li:hover {
  background-color: antiquewhite;
}

div.new  {
  border: 3px solid #41b883;
}

div.updated {
  border: 3px solid orange;
}

div.deleted {
  text-decoration: line-through;
  color: var(--color-txt);
  opacity: 0.5;
  pointer-events: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: var(--color-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease, color 0.5s ease;
  overflow-y: visible;
  position: relative;
  white-space: nowrap;
}

.scrollable {
  overflow-x: auto;
  flex-grow: 1;
}

.content {
  display: flex;
  align-items: center;
}

.content > * {
  flex-shrink: 0;
  margin-left: 12px;
}

.field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 125px;
  max-height: 50%;
}

.field > select, .field > input {
  background: var(--color-bg-sec);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  color: var(--color-txt);
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
}

small {
  margin: 0!important;
  padding: 0!important;
  font-size: 0.75em;
  color: var(--color-txt);
  white-space: nowrap;
}

.text-muted {
  color: var(--color-txt)!important;
}

.bi-trash {
  cursor: pointer;
  padding: 0 0 0 12px;
}

.bi-trash:hover {
  color: red;
}
.select {
  cursor: pointer;
}
</style>