<template>
  <div class="card overflow-visible" style="margin-top: 20px">
    <div class="card-header align-content-between sticky-top">
      <h5 class="card-title">
      Alarms
      </h5>
      <div style="float: right">
        <button @click="addNewAlarm" class="btn btn-outline-primary">
          <font-awesome-icon icon="plus" style="font-size: 11pt" />
          Add Alarm
        </button>
        <button @click="reset" class="btn btn-outline-primary" :disabled="!updatedAlarms">
          <i class="bi bi-arrow-counterclockwise" style="font-size: 11pt"/>
          Reset
        </button>
        <button
            @click="saveAlarms"
            class="btn btn-outline-primary"
            id="saveBtn"
            :disabled="!updatedAlarms"
        >
          <font-awesome-icon icon="satellite" style="font-size: 11pt" />
          Save & Queue
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="alarm-list" :key="listKey">
        <AlarmItem
          v-for="a in alarm_set"
          v-bind:key="a.alarm_id"
          :id="a.alarm_id"
          :alarm_register="a.alarm_register"
          :alarm_type="a.alarm_type_id"
          :alarm_action="a.alarm_action_type_id"
          :hysteresis="a.alarm_set_point_2"
          :alarm_value="a.alarm_set_point"
          :toWhom="a.toWhom"
          :sensor_id="a.sensor_id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue';
import { useStore } from 'vuex';
import AlarmItem from './alarm-item';

export default {
  components: {
    AlarmItem,
  },
  setup() {
    const store = useStore();
    const listKey = ref(0); // Used to force re-render
    return {
      alarm_set: computed(() => store.state.alarm.alarm_set),
      sensor_id: computed(() => store.state.sensor.id),
      saveAlarms: () => store.dispatch('alarm/SaveAlarms'),
      addNewAlarm: () => store.dispatch('alarm/AddNewAlarm'),
      updatedAlarms: computed(() => store.state.alarm.alarm_set.some(a => a.status !== 'existing')),
      reset: () => {
        store.commit('alarm/resetAlarmSet');  // reset store
        listKey.value += 1; // force re-render
      },
      listKey,
    }
  },
};
</script>

<style scoped>
.alarm-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 16px; /* Add space between each alarm item */
  width: 100%; /* Ensure it occupies the full width of its parent */
}

.card-block {
  padding: 16px; /* Add padding around the block */
}
</style>

