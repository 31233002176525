<template>
  <div id="app">
    <!-- Spinner Section -->
    <div v-if="isLoading" class="spinner-container">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Main Content Section -->
    <div v-else>
      <!-- Group Header Section -->
      <div class="group-header">
        <h1>
          {{ title }}
          <i v-if="pendingMsgIcon"
             class="bi bi-envelope-fill"
             title="This gateway has pending messages"
             style="font-size: 0.5em; color:var(--color-sec);"/>
        </h1>
        <button v-if="level==='sensor'" class="button start-wizard-button" @click="showWizard">
          <i class="bi bi-gear"/> Configure Gateway
        </button>
        <p style="margin: 0">Last Updated</p>
        <p v-if="computedLastUpdated">{{ moment(computedLastUpdated).local().format('MMM DD, YYYY hh:mm A z') }}</p>
        <p v-else>No Date Found</p>
      </div>

      <!-- Summary Section -->
      <div class="summary-section">
        <div
            class="summary-item"
            v-for="(item, index) in dynamicSummaryItems"
            :key="index"
            :class="{ selected: selectedFilter === item.name }"
            @click="filterItems(item.name)"
        >
          <span>{{ item.label }}:</span> <span>{{ item.value }}</span>
        </div>
      </div>

      <!-- Generic Infographic Section -->
      <transition-group name="item" tag="div" class="item-cards">
        <div
            v-for="item in filteredItems"
            :key="item.id"
            :class="['item-card', getStatus(item)]"
            @click="navigateTree(item.id, level)"
            class="d-flex flex-column justify-content-between"
        >
          <!-- Icons Section -->
          <div class="icon-section position-absolute top-0 end-0 p-1 d-flex">
            <!-- Alarm Icon -->
            <i v-if="item.alarm_count_active > 0"
               class="bi bi-alarm-fill text-danger me-2"
               style="animation: blinker 1s linear infinite;"
               :title="`${item.alarm_count_active} Active Alarms`"
            />
            <i v-if="item.alarm_count > 0 && item.alarm_count_active <= 0"
               class="bi bi-alarm me-2" style="color: var(--color-txt)"
               :title="`${item.alarm_count} ${item.alarm_count > 1 ? 'Alarms': 'Alarm'} Set`"
            />
            <!-- Pending Messages Icon -->
            <i v-if="item.message_queue_count > 0"
               class="bi bi-envelope-fill me-2" style="color: var(--color-sec)"
               :title="`${item.message_queue_count} Pending Messages`"
            />
            <!-- Errors & Timeouts Icon -->
            <i v-if="item.error_count > 0 || item.timeout_count > 0"
               class="bi bi-exclamation-triangle-fill text-warning"
               :title="`${item.error_count} Errors & ${item.timeout_count} Timeouts`"
            />
          </div>

          <!-- Name at the Top of card -->
          <div class="item-name text-center mb-2">
            {{ processChildLabel(item.label) }}
          </div>

          <!-- Content Section: Sparkline or Item Count -->
          <div class="d-flex justify-content-between align-items-center">
            <!-- Sparkline for Sensors -->
            <D3Sparkline v-if="level === 'sensor'" :readings="data(item.id)?.value"/>

            <!-- Item Count for Other Types -->
            <div v-else class="last-data text-center">
              <div class="item-count"> {{ childCountLabel }} </div>
              <div class="item-count-number"> {{ item[`${childCountField}`] }} </div>
            </div>

            <!-- Last Data Section for Root and Group Levels -->
            <div v-if="level !== 'sensor'" class="last-data text-end">
              <div v-if="item.last_update != null">{{ moment(item.last_update).local().format('MMM DD, YYYY') }}</div>
              <div v-if="item.last_update != null">{{ moment(item.last_update).local().format('hh:mm A z') }}</div>
              <div v-if="item.last_update == null">No Date</div>
              <div v-if="item.last_update == null">Found</div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>


<script setup>
import {computed, defineProps, ref, watch} from 'vue';
import {useStore} from 'vuex';
import moment from 'moment';
import D3Sparkline from '@/components/shared/charts/D3Sparkline.vue';

const props = defineProps({
  title: String,
  lastUpdated: String,
  items: Array,
  level: String,
  summaryItems: Array,
  childLabel: String,
  childCountLabel: String,
  childCountField: String,
  pendingMsgIcon: Boolean
});

const store = useStore();
const selectedFilter = ref('total');
const reactiveItems = ref([]);

let data = (sensorId) => computed(() => store.state.gateway.readings[sensorId]);

const isLoading = computed(() => store.state.settings.is_loading);

// Watch for changes in props.items
watch(() => props.items, (newItems) => {
  reactiveItems.value = newItems;
}, { immediate: true }); // Run the watch immediately to handle the initial state

// Compute lastUpdated based on the items if not provided
const computedLastUpdated = computed(() => {
  if (props.lastUpdated) {
    return props.lastUpdated;
  } else if (reactiveItems.value && reactiveItems.value.length > 0) {
    const maxDate = Math.max(...reactiveItems.value.map(item => new Date(item.last_update || 0)));
    return maxDate ? new Date(maxDate) : null;
  }
  return null;
});

const getStatus = item => {
  if (item == null) return 'inactive';
  if (item.status) {
    return item.status;
  } else if (item.online_status != null) {
    return item.online_status === 'Online' ? 'active' : 'inactive';
  } else {
    if (props.level === 'sensor') {
      return getSensorActive(item);
    }
  }
}

const getSensorActive = (sensor) => {
  // see if the sensor is in the gcr
  let gcrPresent = store.state.readingControl.controls.some(gcr => gcr.sensor_id === sensor.id);
  if (!gcrPresent) {
    return 'inactive';
  }

  // get the last reading for this sensor
  let sensorReadings = store.state.gateway.readings[sensor.id];
  if (sensorReadings == null || Object.keys(sensorReadings).length === 0) {
    return 'inactive';
  }

  let latestReading = sensorReadings[Object.keys(sensorReadings)[0]];
  for (let address in sensorReadings) {
    let register = sensorReadings[address];
    if (register[0].receive_datetime == null) {
      return 'inactive';
    }
    if (register[0].receive_datetime > latestReading[0].receive_datetime) {
      latestReading = register;
    }
  }
  sensor.last_update = latestReading?.receive_datetime;

  // get the timings for this gateway
  let commFreq = store.state.readingControl.timings?.commFreq?.attribute_value;
  let timeDiff = moment().diff(moment(latestReading?.receive_datetime), 'minutes');
  let isActive = timeDiff < commFreq;

  return isActive ? 'active' : 'inactive';
}

// Dynamically generate summaryItems if not provided
const dynamicSummaryItems = computed(() => {
  if (props.summaryItems && props.summaryItems.length > 0) {
    return props.summaryItems;
  } else {
    const total = reactiveItems.value.length;
    const active = reactiveItems.value.filter(item => getStatus(item) === 'active').length;
    const inactive = total - active;

    return [
      { name: 'total', label: `Total ${props.childLabel}s`, value: total },
      { name: 'active', label: `Active ${props.childLabel}s`, value: active },
      { name: 'inactive', label: `Inactive ${props.childLabel}s`, value: inactive }
    ];
  }
});

const filteredItems = computed(() => {
  if (selectedFilter.value === 'active') {
    return reactiveItems.value.filter(item => getStatus(item) === 'active');
  } else if (selectedFilter.value === 'inactive') {
    return reactiveItems.value.filter(item => getStatus(item) === 'inactive');
  } else {
    return reactiveItems.value;
  }
});

const filterItems = (filter) => {
  selectedFilter.value = filter;
};

// Method to process the label
const processChildLabel = (label) => {
  if (label && label.includes(' - ')) {
    return label.split(' - ').slice(1).join(' - ');
  }
  return label;
};

const navigateTree = (id, level) => {
  store.dispatch('MyView/click', { id, level });
};

const showWizard = () => {
  store.commit('ConfigWizard/setShowModal', true);
};
</script>


<style scoped>
#app {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  color: var(--color-txt);
  background-color: var(--color-bg);
  transition: background 0.5s ease, color 0.5s ease;
}

.group-header {
  text-align: center;
  margin-bottom: 20px;
}

.summary-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.summary-item {
  background-color: var(--color-bg-sec);
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  white-space: nowrap;
}

.summary-item:hover {
  border-color: var(--active);
}

.summary-item.selected {
  background-color: var(--btn-pressed);
}

.item-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  gap: 20px;
  place-items: center
}

.item-card {
  background-color: var(--color-bg-sec);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 250px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-card .item-name {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--color-txt);
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.item-card.active {
  box-shadow: 0 4px 8px rgba(0, 255, 0, 0.4);
}

.item-card.inactive {
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.4);
}

.item-card:hover {
  border-color: var(--active);
  transform: translateY(-5px);
}

.item-name {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}

.item-count {
  font-size: 1.2em;
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}

.item-count-number {
  font-size: 1.6em;
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}

.last-data {
  font-size: 1em;
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}

.item-enter-active, .item-leave-active {
  transition: all 0.5s ease;
}

.item-enter, .item-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.item-enter {
  animation: enterAnimation 0.5s forwards;
}

.item-leave-active {
  animation: leaveAnimation 0.5s forwards;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes leaveAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.group-header {
  position: relative;
  padding: 20px;
  background-color: var(--color-bg);
  color: var(--color-txt);
}

/* Add Gateway Button Styling */
.start-wizard-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 1.1em;
  background-color: var(--color-bg-sec);
  color: var(--color-txt);
  border: 2px solid var(--active);
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.start-wizard-button:hover {
  background-color: var(--active);
  color: var(--color-bg-sec);
  transform: translateY(-2px);
}

.start-wizard-button:disabled {
  background-color: var(--btn-pressed);
  cursor: not-allowed;
  opacity: 0.6;
}

.bi-gear {
  font-size: 1.1em;
  margin-right: 4px;
  height: 1em;
  line-height: 1;
  vertical-align: middle;
}
</style>
